import { BooleanField, DateField, Show, SimpleShowLayout, TextField } from "react-admin";

export const PropertyShow = () => (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="userId" label="User ID" />
        <TextField source="editorId" label="Editor ID" />
        <TextField source="housingId" label="Housing ID" />
        <TextField source="accountId" label="Account ID" />
        <TextField source="vPropertyId" label="Virtual Property ID" />
        <BooleanField source="new" label="New" />
        <TextField source="access" label="Access" />
        <BooleanField source="blocked" label="Blocked" />
        <BooleanField source="isHotel" label="Is Hotel?" />
        <TextField source="address" label="Address" />
        <TextField source="zipcode" label="Zip Code" />
        <TextField source="city" label="City" />
        <TextField source="country" label="Country" />
        <TextField source="location" label="Location" />
        <TextField source="vat" label="VAT" />
        <TextField source="immatriculation" label="Immatriculation" />
        <TextField source="checkIn" label="Check-In" />
        <TextField source="checkOut" label="Check-Out" />
        <TextField source="deposit" label="Deposit" />
        <TextField source="touristTax" label="Tourist Tax" />
        <TextField source="ownerStatus" label="Owner Status" />
        <TextField source="cancelRule" label="Cancel Rule" />
        <TextField source="reservationMode" label="Reservation Mode" />
        <TextField source="currency" label="Currency" />
        <TextField source="note" label="Note" />
        <TextField source="nbNote" label="Number of Notes" />
        <BooleanField source="bypassAccount" label="Bypass Account" />
        <DateField source="createdAt" label="Created At" />
        <DateField source="updatedAt" label="Updated At" />
      </SimpleShowLayout>
    </Show>
  );
  