import { List, Datagrid, TextField, NumberField, DateField } from 'react-admin';
import GuestCommentPagination from './GuestCommentPagination';

export const GuestCommentList = () => (
  <List pagination={<GuestCommentPagination />}>
    <Datagrid>
      <TextField source="id" />
      <NumberField source="rate" label="Rate" />
      <TextField source="message" label="Message" />
      <DateField source="createdAt" label="Created At" />
      <DateField source="updatedAt" label="Updated At" />
    </Datagrid>
  </List>
);

export default GuestCommentList;
