import { List, Datagrid, TextField, ImageField } from 'react-admin';

const HomePicture = () => (
    <List>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="description" />
            <ImageField source="url" title="title" />
        </Datagrid>
    </List>
);

export default HomePicture;
