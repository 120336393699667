import { Datagrid, DateField, List, TextField } from "react-admin";
import { PropertyPagination } from "./PropertyPagination";

export const PropertyList = () => (
    <List pagination={<PropertyPagination />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="access" label="Access" />
        <TextField source="address" label="Address" />
        <TextField source="city" label="City" />
        <TextField source="country" label="Country" />
        <TextField source="location" label="Location" />
        <DateField source="createdAt" label="Created At" />
        <DateField source="updatedAt" label="Updated At" />
      </Datagrid>
    </List>
  );