import { DateField, EmailField, Show, SimpleShowLayout, TextField } from "react-admin";

export const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="nom" label="Last Name" />
      <TextField source="prenom" label="First Name" />
      <EmailField source="email" />
      <TextField source="rue" label="Street" />
      <TextField source="cp" label="Postal Code" />
      <TextField source="ville" label="City" />
      <TextField source="pays" label="Country" />
      <TextField source="telephone" label="Phone" />
      <TextField source="isValidTelephone" label="Is Phone Valid?" />
      <TextField source="role" label="Role" />
      <TextField source="language" label="Language" />
      <TextField source="timezone" label="Time Zone" />
      <TextField source="civility" label="Civility" />
      <TextField source="registerType" label="Register Type" />
      <TextField source="acceptNewsletter" label="Newsletter Acceptance" />
      <TextField source="sponsorCode" label="Sponsor Code" />
      <TextField source="presentation" label="Presentation" />
      <TextField source="confirmation" label="Confirmation" />
      <DateField source="confirmationCreatedAt" label="Confirmation Created At" />
      <TextField source="token" label="Token" />
      <DateField source="tokenCreatedAt" label="Token Created At" />
      <TextField source="reset" label="Reset" />
      <DateField source="resetCreatedAt" label="Reset Created At" />
      <DateField source="createdAt" label="Created At" />
      <DateField source="updatedAt" label="Updated At" />
    </SimpleShowLayout>
  </Show>
);
