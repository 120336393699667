import { Show, SimpleShowLayout, TextField, NumberField, DateField } from 'react-admin';

export const GuestCommentShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="userId" label="User ID" />
      <TextField source="propertyId" label="Property ID" />
      <TextField source="reservationId" label="Reservation ID" />
      <NumberField source="rate" label="Rate" />
      <TextField source="message" label="Message" />
      <DateField source="createdAt" label="Created At" />
      <DateField source="updatedAt" label="Updated At" />
    </SimpleShowLayout>
  </Show>
);

export default GuestCommentShow;
