import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';

export const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    return fetchUtils.fetchJson(url, options);
}

export const dataProvider = jsonServerProvider(
    process.env.REACT_APP_JSON_SERVER_URL as string,
    httpClient
);
