import { Datagrid, EmailField, List, TextField } from "react-admin";
import { UserPagination } from "./UserPagination";

export const UserList = () => (
  <List pagination={<UserPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="nom" label="Last Name" />
      <TextField source="prenom" label="First Name" />
      <EmailField source="email" />
      <TextField source="telephone" label="Phone" />
      <TextField source="createdAt" label="Created At" />
      <TextField source="updatedAt" label="Updated At" />
    </Datagrid>
  </List>
);