import { useEffect, useState } from 'react';
import { Create, SimpleForm, TextInput, required, SelectInput, AutocompleteInput } from 'react-admin';
import validateRate from '../../utils/validateRate';
import { httpClient } from '../../dataProvider';

const apiUrl = process.env.REACT_APP_JSON_SERVER_URL;

export const GuestCommentCreate = () => {
  const [userOptions, setUserOptions] = useState([]);
  const [propertyOptions, setPropertiesOptions] = useState([]);

  useEffect(() => {
    const fetchUserOptions = async () => {
      try {
        const response = await httpClient(`${apiUrl}/users/details`);
        const users = await response.body;
        const options = JSON.parse(users).map((user: any) => ({
          id: user.id,
          name: `${user.nom} ${user.prenom}`
        }));
        setUserOptions(options);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    const fetchPropertiesOptions = async () => {
      try {
        const response = await httpClient(`${apiUrl}/properties/details`);
        const properties = await response.body;
        const options = JSON.parse(properties).map((property: any) => ({
          id: property.id,
          name: property.address
        }));
        setPropertiesOptions(options);
      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    };

    fetchUserOptions();
    fetchPropertiesOptions();
  }, []);

  return (
    <Create>
      <SimpleForm>
        <AutocompleteInput
          source="userId"
          label="User"
          choices={userOptions}
          optionText="name"
          optionValue="id"
          validate={required()}
        />
        <AutocompleteInput
          source="propertyId"
          label="Property"
          choices={propertyOptions}
          optionText="name"
          optionValue="id"
          validate={required()}
        />
        <SelectInput 
          source="rate" 
          label="Rate" 
          choices={[
            { id: 20, name: '20%' },
            { id: 40, name: '40%' },
            { id: 60, name: '60%' },
            { id: 80, name: '80%' },
            { id: 100, name: '100%' }
          ]} 
          validate={[required(), validateRate]} 
        />

        <TextInput source="message" label="Message" validate={required()} multiline />
      </SimpleForm>
    </Create>
  );
};

export default GuestCommentCreate;
