// App.tsx
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Admin, Resource } from 'react-admin';
import { dataProvider } from './dataProvider';
import GuestCommentList from './components/guest-comment/GuestCommentList';
import GuestCommentShow from './components/guest-comment/GuestCommentShow';
import GuestCommentCreate from './components/guest-comment/GuestCommentCreate';
import { PropertyList } from './components/property/PropertyList';
import { PropertyShow } from './components/property/PropertyShow';
import { UserList } from './components/user/UserList';
import { UserShow } from './components/user/UserShow';
import authProvider from './security/authProvider';

// Import Material-UI icons
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '@mui/icons-material/Home';
import CommentIcon from '@mui/icons-material/Comment';
import HomePicture from './components/picture/home/HomePicture';

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="users" icon={PeopleIcon} list={UserList} show={UserShow} />
        <Resource name="properties" icon={HomeIcon} list={PropertyList} show={PropertyShow} />
        <Resource name="guest-comments" icon={CommentIcon} list={GuestCommentList} show={GuestCommentShow} create={GuestCommentCreate} />
        <Resource name="pictures/home" list={HomePicture}/>
      </Admin>
    </I18nextProvider>
  );
};

export default App;
