import axios from 'axios';
import { AuthProvider } from 'react-admin';

const apiUrl = process.env.REACT_APP_JSON_SERVER_URL;

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        try {
            const response = await axios.post(`${apiUrl}/auth/login`, {
                username,
                password,
            }, {
                headers: { 'Content-Type': 'application/json' },
            });
    
            const { token } = response.data;
            localStorage.setItem('token', token);
        } catch (error: any) {
            throw new Error(error.response?.statusText || 'Network error');
        }
    
        return Promise.resolve();
    },

    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },

    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },

    checkAuth: () =>
        localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

    getPermissions: () => Promise.resolve()
};

export default authProvider;
